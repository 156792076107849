@import "scss/Common";

.float-dialog {
  position: fixed;
  z-index: 1210;
  visibility: hidden;
  opacity: 0;
  //transition: 200ms ease-in-out opacity, 200ms ease-in-out visibility;
  background-color: white;
  display: inline-block;
  box-shadow: 0 0 2px 0 rgba(34,47,62,.2), 0 4px 8px 0 rgba(34,47,62,.15);
  padding: 4px 8px;
  border-radius: 6px;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  a, a:hover, a:visited {
    display: block;
    font-size: 13px;
    font-weight: bold;
    line-height: normal;
  }
}