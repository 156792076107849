@import "../../components/scss/Common";

.main-menu {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  background-color: $colorWhite;

  .controls {
    padding-left: $space16;
    padding-right: $space16;

    button {
      font-size: 18px;
      display: inline-block;
      padding: $space12;
      text-decoration: none;
      cursor: pointer;
      border: none;
      background: transparent;
    }
  }
}