@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.identification-modal {
  &__text-alignment {
    flex-direction: column;

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 5px;

      &--left, &--center, &--right {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 24px;
        width: 28px;
        height: 28px;
        border: 1px solid #1B1B1A;
        border-radius: 5px;
        cursor: pointer;
      }

      [data-selected="true"] {
        background-color: #DEDEDE;
      }

      &--left {
        $imageSet: url('../components/images/text-alignment/align-left.png') 1x, url('../components/images/text-alignment/align-left@2x.png') 2x;
        background-image: image-set($imageSet);
        background-image: -webkit-image-set($imageSet);
      }

      &--center {
        $imageSet: url('../components/images/text-alignment/align-center.png') 1x, url('../components/images/text-alignment/align-center@2x.png') 2x;
        background-image: image-set($imageSet);
        background-image: -webkit-image-set($imageSet);
      }

      &--right {
        $imageSet: url('../components/images/text-alignment/align-right.png') 1x, url('../components/images/text-alignment/align-right@2x.png') 2x;
        background-image: image-set($imageSet);
        background-image: -webkit-image-set($imageSet);
      }
    }
  }

  &__checkboxes {
    display: flex;
    justify-content: flex-end;
  }
}


