@import 'Common';
@import 'GridCommon';

.modal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 200ms ease-in-out opacity, 200ms ease-in-out z-index, 200ms ease-in-out visibility;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  overflow-y: auto;

  .backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #707070;
    opacity: 0.5;
    z-index: -1;
  }

  .container {
    display: inline-block;
    text-align: left;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    padding: $space40;
    transition: 200ms ease-in-out transform;
    transform: scale(1.1);
    margin: $space32 0;
    max-width: $desktopWidth;
    overflow: auto;

    .close-button {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;
    }

    &.full-space {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.show {
    visibility: visible;
    z-index: 10000;
    opacity: 1;

    .container {
      transform: scale(1);
    }
  }

  .form-controls {
    flex-direction: column;
    align-items: flex-end;
    margin-top: $space40;

    > a, button {
      margin-bottom: $space16;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.default-modal {
    .container {
      h2 {
        margin-bottom: $space16;
      }

      .body,
      .message {
      }

      .controls {
        margin-top: $space40;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        button, .button {
          margin-left: $space16;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.modal-search {
  display: flex;
  justify-content: end;
  align-items: start;
  position: absolute;
  margin-top: 5rem;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  transition: 200ms ease-in-out opacity, 200ms ease-in-out z-index, 200ms ease-in-out visibility;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  pointer-events: none; /* Permite clicar através do modal */

  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparent; /* Torna o backdrop transparente */
  }

  .container {
    display: inline-block;
    text-align: left;
    position: relative;
    background-color: rgba(255, 255, 255, 1); /* Levemente transparente */
    border-radius: 10px;
    box-shadow: 0 3px 6px #00000029;
    padding: $space80 $space90;
    transition: 200ms ease-in-out transform;
    transform: scale(1.1);
    margin: $space32 0;
    max-width: $desktopWidth;
    overflow: auto;
    pointer-events: auto; /* Permite interação com os elementos dentro do container */

    .close-button {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    &.full-space {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.show {
    visibility: visible;
    z-index: 1500;
    opacity: 1;

    .container {
      transform: scale(1);
    }
  }

  .form-controls {
    flex-direction: column;
    align-items: flex-end;
    margin-top: $space40;

    > a, button {
      margin-bottom: $space16;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.default-modal {
    .container {
      h2 {
        margin-bottom: $space32;
      }

      .body,
      .message {
      }

      .controls {
        margin-top: $space40;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        > * {
          margin-left: $space16;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.controls {
  position: relative;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropbtn {
  background-color: #f1f1f1;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.gear-icon {
  font-size: 20px;
  margin-right: 5px;
}

.dropdown-content {
  display: grid;
  position: absolute;
  bottom: 100%; /* Define a posição para cima do botão */
  left: 0;
  background-color: #ffffff;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 9999; /* Garanta que o dropdown fique acima do modal principal */
  border: 1px solid #ddd;
  max-height: none; /* Remove o limite de altura */
  overflow: visible; /* Remove o overflow */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content label {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
}

.dropdown-content input[type='checkbox'] {
  margin-right: 10px;
}
