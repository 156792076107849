@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.pagination-modal {
  .container {
    display: flex;
    flex-direction: column;
  }

  .table-form-container {
    flex: 1;
  }

  .pagination-modal-form {
    padding: $space16 0;
    border: 1px solid $colorGray;
    border-radius: 4px;

    .form-items{
      display: flex;
      flex-direction: column;
      padding: $space16;

      .form-item {
        display: flex;
        flex-direction: column;
        margin-bottom: $space16;

        label {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: $space8;
        }

        input {
          border: 1px solid $colorGray;
          border-radius: 4px;
          font-size: 14px;
          padding: $space8;
        }


        label:not(.field-label) {
          color: $colorValidationError;
        }
      }
    }

  }
}
