@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.dictionary-modal-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .table-container {
    height: 100%;
    position: relative;
    overflow: auto;
    border: 1px solid #767578;
    border-radius: 4px;
    flex: 1;
    margin-top: $space8;

    .status {
      text-align: center;
    }

    .scroll {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      position: absolute;

      .grid {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: flex-end;
        justify-content: space-evenly;
        grid-auto-columns: auto;
        justify-items: center;
        padding: 0 $space40;

        > * {
          padding-top: $space8;
          padding-bottom: $space8;
        }
      }
    }
  }
}

