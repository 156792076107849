@import "../components/scss/Common";
@import "../components/scss/GridCommon";

table.figures-table {
  thead {
    tr {
      th {
        padding: 2px 24px;
        font-size: 14px;
      }
    }
  }

  tbody {
    .column-preview-braille {
      padding: 0;
      text-align: center;
      vertical-align: center;

      .preview-braille-container {
        width: 512px;
        height: 256px;
        position: relative;
      }
    }

    .tags {
      font-size: 14px;
    }
  }
}

//.preview-braille {
//  display: grid;
//  gap: 1px;
//  width: 100%;
//  overflow: hidden;
//  padding: 0 16px 40px 5px;
//}


