@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.covers-modal-list {
  display: flex;
  flex: 1;
  flex-direction: column;

  .table-container {
    position: relative;
    overflow: auto;
    border: 1px solid #767578;
    border-radius: 4px;
    flex: 1;
    margin-top: $space8;

    .status {
      text-align: center;
    }

    .scroll {
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      position: absolute;
    }
  }
}

