@import "../../../../components/scss/Common";

.revision-modal {
  .container {
    min-width: 380px;
    padding: 40px;
  }

  .record-container {
    .record {
      display: flex;
      flex-flow: row;
      align-items: center;
      font-size: 14px;
      line-height: normal;
      background-color: $colorLoggedBackground;
      padding: $space8;
      border-radius: 4px;
      box-shadow: 0.5px 0.5px 1.5px 0 rgba(0,0,0,0.6);
      margin-bottom: $space8;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      .description {
        flex: 1;
        padding-right: $space16;
        word-wrap: normal;

        .detail {
          font-size: 12px;
          font-style: italic;
        }
      }

      .addressing {
        text-align: right;
        > * {
          font-size: 10px;
          &:before {
            font-weight: bold;
          }
        }
        .page {
          &:before {
            //I18N
            content: 'Página: ';
          }
        }
        .paragraph {
          &:before {
            //I18N
            content: 'Parágrafo: ';
          }
        }
      }
    }
  }
}