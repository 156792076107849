@import '../components/scss/Common';

.popup-menu-backdrop {
  position: fixed;
  z-index: 5000;
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &.show {
    display: unset;
  }
}

.popup-menu {
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  border: 1px solid $colorGray;
  border-radius: 4px;
  background-color: #FFF;
  line-height: normal;
  font-size: 13px;
  color: $colorDark;
  text-transform: none;
  font-weight: normal;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: 100ms ease-in-out transform, 100ms ease-in-out opacity, 100ms ease-in-out z-index, 100ms ease-in-out visibility;
  transform-origin: top;
  transform: scale(0.8);
  box-shadow: 0 3px 6px #00000029;

  .item {
    color: $colorTinyMceIcon;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: $space12 $space12;
    cursor: pointer;
    width: 100%;
    text-align: left;
    color: $colorTinyMceIcon;

    &:hover {
      background: $colorLightGray;
    }

    .icon {
      width: 24px;
      margin-right: $space8;

      img {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }
  &.show {
    visibility: visible;
    z-index: 5010;
    opacity: 1;
    transform: scale(1);
  }
}