@import 'src/components/scss/Common';
@import 'src/components/scss/GridCommon';

.document-modal {
  &.show {
    z-index: 10;
  }

  .container {
    padding: $space80 0;
    width: unset;

    .top {
      display: flex;
      justify-content: space-between; 

      h2 {
        display: block;
        margin-bottom: $space40;
        padding: 0 $space40;
        text-align: center;
        width: 100%;

        &.left {
          display: inline-block;
          text-align: left;
          width: auto;
        }
      }

      button {
        align-self: center;
        margin: 0 $space80;

        &.file {
          display: inline-flex;
          align-items: center;
          border: 1px solid #878787;
          border-radius: 4px;
          font-weight: normal;
          margin: 0 $space80;
          padding: 15px 16px;
          position: relative;
          font-size: 13px;
          line-height: normal;

          img {
            margin-left: 16px;
          }
        }
      }
    }
    
    h3 {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0 $space16;
      line-height: 28px;

      //@media ($mediaTabletCondition) {
      //  font-size: 20px;
      //}
    }

    form {
      display: block;
    }
  }

  .header-divisor {
    border-bottom: 1px solid $colorLightGray;
    padding-bottom: $space16;
    margin-bottom: $space24;
  }

  .orientation-controls {
    display: inline-flex;
    align-items: flex-end;

    > .button-orientation {
      margin-right: $space8;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}