@import "Common";

@mixin editorElementRecoil() {
  editor-element[type="recoil"] {
    display: block;
    background-color: $colorLightGray;

    > .text {
      padding-left: 2ch;
    }

    &[data-continuation="true"] {
      > .text {
        &:before {
          display: inline;
          content: '\00a0\00a0';
        }
      }
    }
  }

  editor-page[data-converted-to-braille="true"] {
    editor-element[type="recoil"] {
      > .text {
        padding-left: 0;
      }
    }
  }
}
