@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.document-modal {
  .templates {
    display: flex;
    flex-direction: column;

    .table-container {
      height: 100%;
      position: relative;

      .status {
        text-align: center;
      }

      .scroll {
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
        position: absolute;

        .grid {
          display: grid;
          grid-template-columns: 50% 50%;
          align-items: flex-end;
          justify-content: space-evenly;
          grid-auto-columns: auto;
          justify-items: center;
          padding: 0 $space40;

          > * {
            padding-top: $space8;
            padding-bottom: $space8;
          }

          //@media ($mediaTabletCondition) {
          //  & {
          //    grid-template-columns: 100%
          //  }
          //}
        }
      }
    }
  }
}