//---------------------------------------------------------
// Colors
//---------------------------------------------------------
$colorYellow: #FFDD2E;
$colorDark: #1D1D1B;
$colorWhite: #FFF;
$colorGray: #878787;
$colorLightGray: #E2E2E2;

$colorBlue: #73E7FC;
$colorRed: #D52E5F;
$colorLightGreen: #74FAC4;
$colorGreen: #4DA830;
$colorOrange: #E35F30;
$colorDarkBlue: #1F2936;

$colorLoggedBackground: #F5F5F5;
$colorValidationError: #EB3223;
$colorBorderInput: #767578;
$colorBorderTinyMceLightSelection: #C2CDFF;
$colorBorderTinyMceSelection: #BFD7FB;

$colorTinyMceIcon: #222f3e;
$colorTinyMceIconHover: #cce2fa;
$colorTinyMceIconSelected: #a6ccf7;

//---------------------------------------------------------
// Fonts
//---------------------------------------------------------
$fontBentonSans: 'PB Benton Sans';
$fontDejavuSans: 'PB DeJavu Sans';
$fontNimbusMono: 'PB Nimbus Mono';
$fontNimbusRoman: 'PB Nimbus Roman';
$fontDejavuSansMono: 'PB DeJavu Sans Mono';

//---------------------------------------------------------
// Spacing
//---------------------------------------------------------
$space8: 8px;
$space12: 12px;
$space16: 16px;
$space24: 24px;
$space32: 32px;
$space40: 40px;
$space64: 64px;
$space80: 80px;
$space90: 90px;
$space140: 140px;

//---------------------------------------------------------
// Line height
//---------------------------------------------------------
$lineHeightH1: 48px;
$lineHeightH2: 30px;
$lineHeightH3: 25px;
$lineHeightH4: 22px;
$lineHeightH5: 20px;
$lineHeightH6: 18px;

//---------------------------------------------------------
// URLs
//---------------------------------------------------------
$urlLoadingDark: '../images/loading-dark.gif';

//---------------------------------------------------------
// Mixins
//---------------------------------------------------------
@mixin font($fontFamily, $fontFileNameWithoutExtension, $fontWeight, $fontStyle, $unicodeRange: unset) {
  @font-face {
    font-family: $fontFamily;
    src: url('../fonts/#{$fontFileNameWithoutExtension}.woff2') format('woff2'),
    url('../fonts/#{$fontFileNameWithoutExtension}.woff') format('woff');
    font-weight: $fontWeight;
    font-style: $fontStyle;
    unicode-range: $unicodeRange;
  }
}
