@import "../components/scss/Common";

.error-loading {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: $space32;
  font-weight: bold;
  font-size: 16px;

  button {
    margin-top: $space12;
  }
}