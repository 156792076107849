@import "Common";

.app-loading {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 200ms ease-in-out opacity;

  .loading {
    display: inline-flex;
    align-items: center;
    font-size: 30px;

    img {
      height: 64px;
      margin-right: $space12;
    }
  }

  &.hide {
    opacity: 0;
  }
}