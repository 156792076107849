@import "../components/scss/Common";

.button-file-mode-view {
  padding: 0;
  width: 22px;
  margin-top: -4px;
  text-align: center;
  margin-right: $space12;
  border-left-color: transparent;
  border-right-color: transparent;

  & img {
    width: 22px;
    height: auto;
  }
}