@import "../../components/scss/Common";

.logged-container {
  height: 100%;

  .background {
    display: inline-block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $colorLoggedBackground;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .container {
      flex: 1;
      padding-top: $space24;
    }
  }
}