@import '../../../../components/scss/Common';

.revision-module-status-bar {
  display: flex;
  flex-direction: row;
  align-items: center;

  .status-icon {
    display: inline-block;
    $size: 10px;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: 1px solid $colorGray;
    cursor: pointer;
  }

  .status-text {
    margin-left: 4px;
    cursor: pointer;
  }

  &.status-ok {
    .status-icon {
      border-color: $colorGreen;
      background-color: $colorLightGreen;
    }
  }

  &.status-warning {
    .status-icon {
      border-color: goldenrod;
      background-color: $colorYellow;
    }
  }

  &.status-error {
    .status-icon {
      border-color: darkred;
      background-color: red;
    }
  }
}


