@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.cover-modal {
  .container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;

    .list-container {
      display: flex;
    }
  }

  .table-form-container {
    flex: 1;
  }
}