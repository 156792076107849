.document-miniature.template {
  cursor: pointer;
}

.template-details {
  max-width: 100%;

  .template-name {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .template-info {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
