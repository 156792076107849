@use "sass:math";
@import "Common";

.hack-menubar-button {
  $margin: 16px;
  margin-left: math.div($margin, 2) !important;
  margin-right: math.div($margin, 2) !important;
  cursor: pointer !important;

  &:first-child {
    margin-left: 0 !important;
  }

  &:last-of-type {
    margin-right: $margin !important;
  }
}

.tox-menubar {
  .document-title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-width: 100%;

    > span {
      padding: 0 $space24;
      font-size: 13px;
      font-weight: bold;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.tox-icon {
  &.tox-tbtn__icon-wrap {
    img {
      display: block;
    }
  }
}

// fix toolbar submenu button width without text (insert symbols for example)
.tox-tbtn.tox-tbtn--select {
  width: unset !important;
}

.tox-tinymce.tox-fullscreen {
  height: 100% !important;
  width: 100% !important;
}

.tox-statusbar {
  height: 32px !important;

  .tox-statusbar__text-container {
    // this is used to show "powered by TinyMce" label in status bar
    // may stop to work after update TnyMce (hack)
    display: none !important;
  }

  .custom-status-bar-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    strong {
      font-weight: bold !important;
    }

    > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 200px;
      margin-right: $space64;

      &:last-child {
        margin-right: 0;
      }

      &.interactive {
        overflow: unset;
      }
    }

    .status-caret-navigation {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .document-status {
      font-weight: bold;

      &:before {
        font-weight: normal;
        content: 'Status: ';
      }
    }

    .designated-users-status {
      font-weight: bold;

      &:before {
        font-weight: normal;
        content: 'Desig.: ';
      }
    }
  }

  .editable {
    padding: 0 4px;
    border: 1px solid gray;
  }

  *[contenteditable="true"] {
    padding: 0 4px;
    border: 1px solid $colorGray;
  }
}

.tox-notification--success {
  background-color: lightgrey !important;
  border-color: gray !important;
}

.tox-notification--error {
  background-color: $colorYellow !important;
  border-color:  #E6C827 !important;
}

.tox .tox-toolbar-overlord .tox-toolbar__primary {
  background: none !important;
}