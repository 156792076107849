@import "../components/scss/Common";

.button-back,
.button-back:hover,
.button-back:focus,
{
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  font-size: 16px;
  text-decoration: underline;
  border: none;
  padding: 0 0 0 $space16;
  font-weight: unset;
  color: unset;
  text-transform: unset;

  img {
    margin-right: $space12;
  }
}