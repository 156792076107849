@import "../components/scss/Common";

.document-miniature {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: calc(100% - #{$space8});

  .miniature-container {
    position: relative;

    .miniature-menu,
    .miniature-expand {
      position: absolute;
      border: 5px solid #FFF;
      background: #FFF;
      margin-right: -6px;
      z-index: 5;
      padding: 0;
      line-height: 0;
    }

    .miniature-menu {
      right: $space8;
      top: $space8;
      padding: 4px 2px;
    }

    .miniature-expand {
      right: $space8;
      bottom: $space8;
      margin-bottom: 0;
      padding: 2px;
    }

    .miniature {
      display: inline-block;
      vertical-align: middle;
      background-color: #FFF;
      box-shadow: 0 3px 6px #00000029;
      margin-bottom: $space8;
      user-select: none;
      position: relative;
      overflow: hidden;

      font-family: DejavuSans, serif;
      line-height: normal;

      .miniature-content {
        overflow: hidden;
        width: 100%;
        height: 100%;

        .content {
          max-height: 100%;
          text-align: left;

          & {
            @import "../components/scss/TinyMceEditorElement";
          }

          editor-element[type="image"] {
            .miniature {
              display: none !important;
            }
          }
        }
      }
    }
  }
}