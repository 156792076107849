@import "../components/scss/Common";

.update-modal {
  visibility: hidden;
  opacity: 0;
  font-size: 13px;
  position: fixed;
  background-color: $colorYellow;
  padding: 4px 8px;
  right: 26px;
  bottom: 30px;
  box-shadow: 0 3px 6px #00000029;
  z-index: 1200;
  text-align: center;
  line-height: normal;
  border-radius: 4px;
  transition: 200ms ease-in-out visibility, 200ms ease-in-out opacity;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  a {
    display: block;
    font-size: 13px;
    font-weight: bold;
    line-height: normal;
  }
}