.tox-statusbar {
  .zoom-container {
    display: flex;
    flex-direction: row;

    .zoom-label {
      font-size: 9px;
      width: 28px;
      text-align: center;
    }
  }
}