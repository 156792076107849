@import '../../../../components/scss/Common';

.record {
  cursor: pointer;

  .description {
    .document-break {

    }
  }
}


