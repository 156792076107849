@import "../components/scss/Common";

.field-share-document {
  .tables-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .field-user-role {
      padding-right: $space16;
    }
  }

  .bottom-controls {
    text-align: right;

    a, .button {
      font-size: 13px;
      line-height: normal;
    }
  }
}