@import "./scss/Common";

.field-textarea {
  font-size: 13px;
  color: #878787;
  line-height: 16px;
  border: 1px solid $colorBorderInput;;
  border-radius: 4px;
  padding: $space8;
  width: 100%;
}