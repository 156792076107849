@import "../components/scss/Common";
@import "../components/scss/GridCommon";

table.covers-table {
  thead {
    tr {
      th {
        padding: 2px 24px;
        font-size: 14px;
      }
    }
  }
}


