@import "Fonts";
@import "Grid";
@import "Common";
@import "Form";
@import "Modal";
@import "AppLoading";
@import "TinyMceEditor";

* {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: $desktopWidth;
}

body {
  font-family: $fontBentonSans;
  font-size: 16px;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 30px;
  color: $colorDark;
}

@import "Headers";

.bg-yellow {
  background-color: $colorYellow;
}

a, a:hover, a:visited, a:focus {
  font-size: 16px;
  line-height: 32px;
  color: $colorDark;
  text-decoration: underline;
  cursor: pointer;
}

.grecaptcha-badge {
  display: none;
}