@use 'sass:math';
@import "Common";
@import "TinyMceEditorElementImage";

$underlineDistance: 4px;

@mixin createEditorElementHeadingStyle() {
  $balloonColor: $colorGray;
  $balloon-width: 80px;
  $balloon-height: 30px;

  & {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 6px;
    width: 100%;

    .container {
      min-width: 2ch;

      .text, .text-2 {
        min-width: 2ch;
      }
    }
  }

  &:after {
    @include balloon($balloonColor, #FFF);
    & {
      font-size: 14px;
      line-height: $balloon-height;
      text-align: center;
      width: $balloon-width;
      height: $balloon-height;
      content: "Título " attr(data-heading-style);
      border-radius: 6px;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      top: 50%;
      margin-top: math.div($balloon-height, -2);
      left: 100%;
      margin-left: $space8;
    }
  }

  &:hover,
  &:focus {
    &:after {
      z-index: 1;
      visibility: visible;
      opacity: 1;
    }
  }
}

@mixin editorElementHeading() {
  editor-element[type="heading-1"] {
    @include createEditorElementHeadingStyle();
    & {
      align-items: center;
      background-color: #f7efd1;
    }

    .container {
      .text, .text-2 {
        text-align: center;
        padding: 0 1ch;
      }

      .text {
        border-bottom: 1px solid black;
        padding-bottom: $underlineDistance;
      }

      .text-2 {
        padding-top: $underlineDistance;
      }
    }
  }

  editor-element[type="heading-2"] {
    @include createEditorElementHeadingStyle();
    & {
      align-items: center;
      background-color: #f5e9cd;
    }

    .container {
      .text {
        text-align: center;
        border-bottom: 1px solid black;
        padding: 0 1ch $underlineDistance 1ch;
      }
    }
  }

  editor-element[type="heading-3"] {
    @include createEditorElementHeadingStyle();
    & {
      background-color: #f0e0c1;
    }

    .container {
      display: flex;
    }
  }

  editor-element[type="heading-4"] {
    @include createEditorElementHeadingStyle();
    & {
      align-items: center;
      background-color: #ccc0a4;
    }
    .container {
      .text {
        text-align: center;
      }
    }
  }

  editor-element[type="heading-5"] {
    @include createEditorElementHeadingStyle();
    & {
      align-items: flex-start;
      background-color: #a19885;
    }

    .container {
      .text {
        padding: 0 1ch 0 2ch;
      }
    }
  }

  editor-page[data-converted-to-braille="true"] {
    editor-element[type="heading-5"] {
      .container {
        .text {
          padding-left: 0;
        }
      }
    }
  }
}
