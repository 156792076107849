@import "./scss/Common";

.field-user-role {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 300px;

  .table-container {
    overflow: auto;
    padding: $space16 0;
    border: 1px solid $colorBorderInput;
    border-radius: 4px;
    flex: 1;
  }

  .extra-controls {
    text-align: right;

    a, .button {
      font-size: 13px;
    }
  }
}