@import "Common";

h1 {
  font-size: 38px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: $lineHeightH1;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: $lineHeightH2;
}

h3 {
  // layout is inconsistent, so I estimate a size for this header
  font-size: 20px;
  font-weight: lighter;
  padding: 0;
  margin: 0;
  line-height: $lineHeightH3;

  strong {
    font-weight: bold;
  }
}

h4 {
  font-size: 16px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  line-height: $lineHeightH4;
}

h5 {
  font-size: 16px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  line-height: $lineHeightH5;

  strong {
    font-weight: bold;
  }
}

h6 {
  font-size: 13px;
  font-weight: normal;
  padding: 0;
  margin: 0;
  line-height: $lineHeightH6;
}

h2.compact {
  font-size: 18px;
  line-height: normal;
  padding-bottom: 0;
  margin-bottom: $space8 !important;
}

.highlight {
  display: inline-block;
  padding: 0 16px;
  background-color: $colorYellow;
}