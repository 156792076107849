@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.recovery-password-form {
    position: relative;
    text-align: center;

    .button-back {
      top: $space80;
      left: 0;
      margin-left: $space24;
      position: absolute;
    }

    h2 {
      margin-bottom: $space40;
    }

    form {
      text-align: center;
      margin-bottom: $space40;
      width: 70%;
      max-width: 100%;

      p {
        text-align: left;
      }

      //@media ($mediaTabletCondition) {
      //  & {
      //    width: 80%;
      //  }
      //}
    }

    .description {
      text-align: start;
    }
}