@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.modal-form {
  .cover-container {
    display: flex;
    flex-direction: row;
    gap: $space8;

    > * {
      width: 100%;
    }
  }
    textarea {
      resize: vertical;
    }
}
