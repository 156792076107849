@import "Common";
@import "TinyMceEditorElementMath";

@mixin editorElementSuppression() {
  $url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAflBMVEVHcEyAAICAAICAAICAAICJAImIAIiAAICAAICAAICAAICCAIKCAIKBAIGAAICBAIGAAICAAICAAICAAICAAICAAICFAIWAAICCAIKAAICAAICBAIGBAIGAAICAAICAAICAAICBAIGOAI6AAICAAICAAICAAICAAICAAICAAIBW5iFEAAAAKXRSTlMAHg6bwQ0P3yC/nT8xc9tXmewCtf4EF/E70Y99TSIQ4d1hCfT4yEhE+3ZS718AAAB9SURBVCjPvZHJEoJADEQDyGQGEdwRlMXd/v8fdC5YBfaBE33IIa/qdVUiMl8W6OMmAp/tBgG1pch4zQprDnbI6f5Q7I8UnFBy0xkVBw4Xur89PkaaxIpNzAC88RSJVZeq8QC87lc/rUItEwYd2vr/gi4cqX7ARL48Cuf69RdHyQq3akjumAAAAABJRU5ErkJggg==';
  $url2x: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAwFBMVEVHcEyAAICAAICBAIGAAICBAIGAAICAAICAAICBAIGAAICBAIGBAIGAAICAAICAAICAAICAAICGAIaAAICAAICSAJKAAICAAICBAIGAAICAAICAAICAAICAAICBAIGBAIGAAICAAICAAICAAICAAICAAICAAICJAImEAISAAICqAKqAAICAAICBAIGAAICAAICAAICAAICAAICAAICAAICAAICBAIGBAIGBAIGAAICAAICAAICAAICAAICAAICAAICDbXdmAAAAP3RSTlMA50ZTSEXmgOVRxUdJz4j+JhQT+9kHgpZfg7vaQA5BVRz2Wko29/oNG5cDjPBLeFSNoQi6/bFpeVduy/Huq+Nioo6BAAABAElEQVRIx+2VWXOCMBSFkUKjoi1tbd3tvtnVurZa7///Vx6ClFEkeGb64IPnhQDfN/cmMIll7bODOTiURPIFg5CTDcn/p0C3FKUB8IaY+/k1hCtC6ICvMKtbh1BlhCKED4J/fBA5PiGENxQoMx19QnhhhDKEW4I/HYictQnhEgWKTEfPEC4YoQmhRfBP4O+/CKEGoc901IPwzghzCHd6VPLCJ45j4n3wr13Ni6jgqkRsgzCCMNQjT7QBXlyD8Iv33+EwQJWSZaG0/ODX9q3YyOKtyWw6/rtR2fxqtHBE8inG+hYWbF/GSeeSG6QTL6u9rRB9OHe7luwlZ7v7g3DXsgD+fEYqxzOhaAAAAABJRU5ErkJggg==';
  $color: #800080;

  editor-element[type="suppression"] {
    @include createElementInlineStyle(
                    $url,
                    $url2x,
                    $color,
    );
  }
}
