@import "../../components/scss/Common";
@import "../../components/scss/TinyMceEditorElementImage";

@mixin editorElementFootnote() {
  $markColor: $colorLightGray;

  editor-element[type="footnote"] {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px dashed $colorGray;

    .text {
      display: block;
    }
  }

  editor-element[type="footnote-mark"] {
    display: inline-block;
    background-color: $markColor;
  }

  editor-element[type="footnote-item"] {
    display: block;

    .mark {
      display: inline;
    }

    .text {
      display: inline;
      position: relative;

      // this forces a space to user can point the caret to type
      &:before,
      &:after {
        display: inline-block;
        transition: 200ms ease-in-out min-width;
        min-width: 0;
        content: ' ';
      }
    }

    &:focus,
    &:hover {
      .text {
        &:before,
        &:after {
          min-width: 7.5px;
        }
        & {
          outline: 2px solid $colorBorderTinyMceLightSelection;
        }
      }
    }
  }
}