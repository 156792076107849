@use 'sass:math';
@import "Common";
@import "TinyMceEditorElementImage";

@mixin createEditorElementHeaderBalloonStyle($content, $zIndex) {
  $balloon-color: $colorGray;
  $balloon-height: 30px;
  $balloon-width: 125px;

  &:after {
    @include balloon($balloon-color, #FFF);
    font-size: 14px;
    line-height: $balloon-height;
    text-align: center;
    width: $balloon-width;
    height: $balloon-height;
    content: $content;
    border-radius: 6px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    margin-top: math.div($balloon-height, -2);
    left: 100%;
    margin-left: $space8;
  }

  &:hover,
  &:focus {
    &:after {
      z-index: $zIndex;
      visibility: visible;
      opacity: 1;
    }
  }
}

@mixin createEditorElementHeaderStyle() {
  $header-item--border-color: #7F7F7F;
  $header-item--height: 1em;

  display: flex;
  justify-content: center;
  position: relative;
  padding: 6px;
  width: 100%;
  user-select: none;

  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    min-height: $header-item--height;
    overflow: hidden;

    .ink-pagination, .identification, .braille-pagination {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 2px;
      border-color: $header-item--border-color;
      border-width: 2px;
      border-style: solid;
    }

    .ink-pagination, .braille-pagination {
      flex-basis: 4em;
      min-height: 100%;
      max-height: $header-item--height;
      text-align: center;
    }

    .ink-pagination {
      @include createEditorElementHeaderBalloonStyle("Tinta", 2);
    }

    .identification {
      flex-basis: 100%;
      min-height: $header-item--height;

      &[data-suppress="true"] {
        opacity: 0.3;
      }

      &[data-text-alignment="left"] {
        text-align: left;
      }

      &[data-text-alignment="center"] {
        text-align: center;
      }

      &[data-text-alignment="right"] {
        text-align: right;
      }

      @include createEditorElementHeaderBalloonStyle("Identificação", 2);
    }

    .braille-pagination {
      @include createEditorElementHeaderBalloonStyle("Braille", 2);
    }
  }
}

@mixin editorElementHeader() {
  editor-element[type="header"] {
    @include createEditorElementHeaderStyle();
  }
}


