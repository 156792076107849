@import "../../components/scss/Common";

.loading-modal {
  .loading-progress {
    border: none;
    margin-right: $space12;
    cursor: unset;
  }

  .container {
    display: inline-flex;
    align-items: center;
    cursor: progress;
    padding: $space32;
  }

  .backdrop {
    cursor: progress;
  }

  &.transparent {
    .backdrop {
      cursor: unset;
      background-color: rgba(255, 255, 255, 0);
    }

    .container {
      display: none;
    }
  }

  &.show {
    z-index: 65535;
  }
}