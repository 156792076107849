@mixin editorElementNthRoot() {
  editor-element[type="nth-root"] {
    display: inline-flex;
    align-items: center;
    position: relative;

    $url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAUCAMAAABlGZcgAAAAolBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACZBL+TAAAANnRSTlMAaDa2ENSTuxETz2CCLH7SZJYnzLGhAXkXyTEaXCJ0bVppvx3CvpwOiAsCA8iNVdqs1cVXDzLK1DjaAAAAeUlEQVQY02NgQAGqqFxlFVS+nDwqX1IahSurhCotrojKF5ZA4Qqxo0rzCaDyuThRuLzMIJKdnV1QDMzn4QaRHBwcojJgPj8LVCEnK4glJQLXycgGJDQU4HwtIyYGBlZ1hNGmOtpMBsh2sTHq6iHzTQz1NVEcY6wGoQHCYQXYRtLyiAAAAABJRU5ErkJggg==';
    $url2x: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAoCAMAAADezbogAAABXFBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADVFLkFAAAAc3RSTlMAGfkFAdgDM3f+Agf1zEzrnujQ4CBVC/f6fkKZ1CdHLIXvkXrkMZQ+3YOK4o82jDg6o4fc5iJ1qPJRHQnVgHarLkatx3AkQbWhDZdpIfA/4/3xfOqWRBNduwSC0yibgXQrO8KsaxcO/FqxvWcRX7e2TxT7doyyzQAAAR5JREFUOMt9kuVTw0AUxNOQpKRCnRZ3d6e4u7u7O+z/P1NIUkhJbt+X25vf7Ny791aSSH3IjEpvKUY/v94ZfsYrw0/yC6G394/MfIMHhq+Sd4R6Nq6ZeQYJhqcDhYQO6QPM3I80w8HBYUL79C5mrkEBw9EqP6FlCDNzEcoZLg3lEVqBEmZuQrGl8s1aycFqRLMUzApqNlqH+qz0GrWEZRvuRkPuY4vrq56/W0+v718zCSz86ja0Opqd1+eysh0dDjybHLEmocRaFOdfRzFmik40u4zCrwbihqiG121Uk5j6OXy1jYrrKMeNCKyhUhD8zdh3dncwIdjEOY4kLaKKFnUYlU92cSZc5KV+cIoL8aLDSB2THOyFsMVysr8dt18z2l4qfQhACGAAAAAASUVORK5CYII=';
    $urlRadicand: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAABAQMAAAAsMPawAAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlPLzMxPTSYAAAAKSURBVAjXY6gAAAB6AHkZ7605AAAAAElFTkSuQmCC';
    $urlRadicand2x: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAACAgMAAAD3OB5AAAAACVBMVEUAAAAAAAAAAACDY+nAAAAAAnRSTlOoqiZOsS8AAAAQSURBVAjXY1i1qoFBNNQBAAxTAn/9TtEZAAAAAElFTkSuQmCC';

    $imageSet: url($url) 1x, url($url2x) 2x;
    //noinspection CssOverwrittenProperties
    background-image: url($url);
    background-image: image-set($imageSet);
    background-image: -webkit-image-set($imageSet);
    background-repeat: no-repeat;
    background-position: top 0 left;
    background-size: 15px 100%;
    min-height: 20px;

    .index {
      position: absolute;
      display: block;
      width: 3ch;
      margin-left: -1ch;
      font-size: 11px;
      padding-bottom: 12px;
      text-align: center;
    }
    .radicand {
      display: inline;
      align-items: center;
      margin-left: 15px;
      padding: 0 1ch 0 2px;
      $imageSet: url($urlRadicand) 1x, url($urlRadicand2x) 2x;
      //noinspection CssOverwrittenProperties
      background-image: url($urlRadicand);
      background-image: image-set($imageSet);
      background-image: -webkit-image-set($imageSet);
      background-position: top 0 left;
      background-repeat: no-repeat;
      background-size: 100% 1.1px;
      min-height: 20px;
    }
  }
}