@import "Common";

@mixin editorElementParagraphBreak() {
  editor-element[type="paragraph-break"] {
    &:before {
      content: "\a";
      white-space: pre;
    }
    &:after {
      content: attr(data-operator);
    }
  }

  editor-element[type="paragraph-break-2sp"] {
    &:before {
      content: "\a   ";
      white-space: pre;
    }
    &:after {
      content: attr(data-operator);
    }
  }

  editor-element[type="paragraph-break-hyphen"] {
    &:before {
      content: "-\a";
      white-space: pre;
    }
    &:after {
      content: attr(data-operator);
    }
  }

  editor-element[type="paragraph-break-hyphen-2sp"] {
    &:before {
      content: "-\a   "; // the first space after the line break is ignored
      white-space: pre;
    }
    &:after {
      content: attr(data-operator);
    }
  }

  editor-element[type="paragraph-break-math"] {
    &:before {
      content: "\a   "; // the first space after the line break is ignored
      white-space: pre;
    }
    &:after {
      content: attr(data-operator);
    }
  }
}
