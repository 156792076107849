@import "../../components/scss/Common";

.crud-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .button-back {
    position: absolute;
    margin-left: $space80;
  }

  .info-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .sub-container-1 {
    flex: 1;

    .sub-container-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        text-align: center;
      }

      .subtitle-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $space12 0;

        .highlight {
          text-transform: uppercase;

          &.bold {
            font-weight: bold !important;
          }
        }
      }

      .submenu-container {
        display: flex;
        flex-direction: row;

        > * {
          margin-right: $space16;

          &:last-child {
            margin-right: 0;
          }
        }

        .button {
          white-space: nowrap;
        }
      }

      .table-container {
        position: relative;
        max-height: 100%;
        flex: 1;

        thead th {
          position: sticky;
          top: 0
        }

        .scroll {
          position: absolute;
          width: 100%;
          height: 100%;
          overflow: auto;
        }
      }

      .form-container {
        margin-bottom: 70px; // height of back button plus main menu padding to perfect centralize in screen

        .controls {
          display: flex;
          margin-top: $space40;

          > div > button:not(:first-child) {
            margin-left: $space24;
          }
        }

        .button-back {
          text-align: left;
          margin-left: $space24;
        }
      }
    }
  }
}