@use 'sass:math';
@import "Common";

form {
  display: inline-block;
}

@mixin formFont {
  font-size: 13px;
  line-break: normal;
  color: $colorGray;
  line-height: 16px;
}

.field-group {
  text-align: left;
  margin-bottom: $space8;

  .validation-error {
    font-size: 13px;
    line-height: normal;
    color: $colorValidationError;
    display: none;
  }

  &.error {
    .validation-error {
      display: block;
    }

    select,
    textarea,
    input {
      border-color: $colorValidationError;
    }

    label:not(.field-label) {
      color: $colorValidationError;
    }
  }
}

label {
  @include formFont();
  margin-bottom: $space8;
  display: flex;
  align-items: center;
}

$inputSmallHeight: 34px;

input,
select {
  @include formFont();
  border: 1px solid $colorBorderInput;
  border-radius: 4px;
  padding: 16px 28px;
  width: 100%;

  &::placeholder {
    color: $colorLightGray;
  }
}

input {
  &.small {
    padding: 8px 14px;
    height: $inputSmallHeight;

    &::placeholder {
      color: $colorGray;
    }
  }
}

select {
  &.small {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &.condensed {
    padding: 8px 20px 8px 8px;
    height: $inputSmallHeight;
    background-position: center right 4px;

    &::placeholder {
      color: $colorGray;
    }
  }
}

input:disabled {
  background-color: $colorLightGray;
}

input[type='checkbox'],
input[type='radio']
{
  width: unset;
  margin-left: 0;
  margin-right: $space8;
}

select {
  appearance: none;
  $arrow1x: '../images/select-arrow.png';
  $arrow2x: '../images/select-arrow@2x.png';
  background-image: url($arrow1x);
  background-image: -webkit-image-set(
                  url($arrow1x) 1x,
                  url($arrow2x) 2x,
  );
  background-image: image-set(
                  url($arrow1x) 1x,
                  url($arrow2x) 2x,
  );
  padding-right: $space40;
  background-repeat: no-repeat;
  background-position: center right $space16;

  &:invalid {
    color: $colorLightGray;
  }

  &.transparent {
    color: $colorDark;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    cursor: pointer;
    border: none;
    font-size: 16px;
    text-decoration: underline;
    text-align: right;
    line-height: normal;
  }
}

input {
  &.transparent  {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $colorDark;
  }
}

.search-field {
  position: relative;

  input {
    z-index: 0;
    &.small {
      padding-left: 60px;
    }
  }

  &:before {
    display: inline-block;
    position: absolute;
    content: ' ';
    $size: 8px;
    $borderWidth: 2px;
    width: $size;
    height: $size;
    border-radius: 50%;
    border: $borderWidth solid $colorBorderInput;
    left: 28px;
    top: 9px;
    z-index: 1;
  }

  &:after {
    display: inline-block;
    position: absolute;
    content: ' ';
    $borderWidth: 2px;
    $width: 8px;
    width: $width;
    height: $borderWidth;
    background-color: $colorGray;
    transform: rotate(45deg);
    left: 36px;
    top: 21px;
    z-index: 1;
  }

  .field-text {
    padding-right: 8px;

    &:last-child {
      padding-right: unset;
    }
  }
}

.form-controls {
  margin-top: $space32;
  text-align: center;
}

button,
.button {
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 16px;
  $border: 2px solid $colorYellow;
  border-top: none;
  border-bottom: none;
  border-left: $border;
  border-right: $border;
  background: none;
  cursor: pointer;
  color: $colorGray;
  text-transform: lowercase;
  text-decoration: none;

  &:visited,
  &:hover,
  &:focus {
    color: $colorGray;
    text-decoration: none;
    line-height: 30px;
    font-size: 16px;
  }

  &.primary {
    color: $colorDark;
  }

  &.loading {
    position: relative;
    color: transparent;

    &:after {
      display: inline-block;
      content: ' ';
      position: absolute;
      $size: 38px;
      width: $size;
      height: $size;
      background-image: url($urlLoadingDark);
      background-size: cover;
      left: 50%;
      margin-left: math.div($size, -2);
      top: 50%;
      margin-top: math.div($size, -2);
    }

    &:before {
      display: inline-block;
      content: attr(data-progress);
      position: absolute;
      $size: 30px;
      width: $size;
      height: $size;
      left: 50%;
      margin-left: math.div($size, -2);
      top: 50%;
      margin-top: math.div($size, -2);
      font-size: 8px;
      color: $colorDark;
      font-weight: normal;
      z-index: 1;
      overflow: hidden;
    }
  }

  &.disabled {
    opacity: 0.2;
    cursor: unset;
  }
}

textarea {
  font-size: 13px;
  line-height: normal;
  font-family: monospace;
}

textarea:disabled {
  background-color: $colorLightGray;
}

section {
  margin: $space8 0;
  h3, h4 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    line-height: unset;
    margin-bottom: $space8;
  }
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0 0 $space8;

  legend {
    font-size: 13px;
    margin-bottom: $space8;
    line-height: normal;
    text-transform: uppercase;
    font-weight: bold;
  }
}

table.crud-table {
  border-collapse: collapse;
  width: 100%;

  th {
    color: $colorWhite;
    background-color: $colorDark;
    font-weight: bold;
    height: 80px;
    padding-left: $space16;
    text-transform: uppercase;
    text-align: start;
  }

  tr:nth-child(even) {
    background-color: $colorLightGray;
  }

  td {
    height: 60px;
    text-align: start;
    padding: 0 $space16;
    font-size: 13px;
    font-weight: lighter;
    line-height: normal;

    &.controls {
      font-size: 16px;
      font-weight: normal;

      a {
        cursor: pointer;
      }
    }
  }
}

.two-columns {
  .field-inputs {
    display: inline-grid;
    grid-template-columns: auto auto;
    column-gap: $space16;
  }
}

.field-label {
  font-weight: bold;
}

.condensed-controls {
  input {
    padding-left: $space12;
    padding-right: $space12;
  }

  select {
    padding-left: $space12;
    padding-right: $space24;
    background-position: center right $space8;
  }
}

.no-wrap-label,
label.no-wrap-label {
  white-space: nowrap;
}

table.selectable {
  width: 100%;

  tbody {
    tr {
      cursor: pointer;
      td {
        border: 1px solid transparent;
        line-height: normal;
        text-align: left;
        padding: 2px $space24;
        vertical-align: middle;
        height: auto;

        &:hover {
          border: 1px solid $colorGray;
        }

        &:first-child {
          width: 1px;
        }
      }

      &.selected {
        td {
          background-color: $colorLightGray;
        }
      }
    }
  }
}