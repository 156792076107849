@import "./scss/Common";

.field-user {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 300px;
  flex: 1;

  .table-container {
    overflow: auto;
    padding: $space16;
    margin-top: $space8;
    border: 1px solid $colorBorderInput;
    border-radius: 4px;
    flex: 1;

    table {
      width: 100%;

      tbody {
        tr {
          cursor: pointer;
          td {
            text-align: left;
            padding: 0;
            vertical-align: middle;
            height: auto;

            &:first-child {
              width: 1px;
            }

            input {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}