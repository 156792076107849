@import "../components/scss/Common";

.template-details-modal {

  &.show {
    z-index: 15;
  }

  .gd {
    width: unset;
  }

  .container {
    padding-left: 0;
    padding-right: 0;

    h2 {
      margin-bottom: $space32;
      line-height: normal;
    }

    .info-container {
      display: flex;
      flex-direction: column;

      .info-block {
        margin-bottom: $space40;

        .info-grid {
          display: grid;
          width: 100%;
          grid-template-columns: 35% 35%;
          font-size: 13px;
          font-weight: lighter;
          line-height: 17px;
          margin-bottom: $space16;

          strong {
            color: $colorGray
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        h3 {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: $space8;
          line-height: normal;
        }
      }

      .controls {
        flex: 1;
        align-items: flex-end;
      }
    }
  }
}