@import "Common";
@import "TinyMceEditorElementSuppression";

@mixin editorElementNonPrintableChars() {
  editor-element[type="representation-space"],
  editor-element[type="representation-nbsp"],
  editor-element[type="representation-line-break"] {
    display: inline-block;
    color: #689DF6;
    text-align: center;
    font-weight: bold;
    pointer-events: none;
    user-select: none;
    white-space: pre-wrap;
  }

  editor-element[type="representation-space"],
  editor-element[type="representation-nbsp"] {
    min-width: 1ch;
    position: relative;
    &:before {
      content: '∙';
    }
  }

  editor-element[type="representation-line-break"] {
    min-width: 0.5ch;
    position: relative;
    &:after {
      content: '¶';
    }
  }

}