@import "../components/scss/Common";

.document {
  .miniature {
    margin-bottom: $space16;
  }

  .document-details {
    max-width: 100%;

    .document-name {
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .document-info {
      font-size: 13px;
      line-height: normal;
      font-weight: 100;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .document-status {
      font-size: 13px;
      font-weight: 100;
      line-height: normal;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }

      .status-indicator {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-left: 4px;
        background-color: transparent;

        img {
          vertical-align: baseline;
        }
      }
    }
  }

  &.view-mode-grid {
    margin: 20px 0;
  }

  &.view-mode-list {
    margin: 10px 0;
    display: contents;

    .miniature-container {
      width: 2em;
    }

    .document-details {
      max-width: 85%;
      font-size: 90%;
      padding-left: 0.8em;

      .document-name {
        font-size: 80%;
      }

      .document-info {
        font-size: 80%;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .document-status {
        font-size: 80%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}