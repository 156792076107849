@import "../components/scss/Common";
.document-settings-modal {
  .orientation-controls {
    display: inline-flex;
    align-items: flex-end;

    > .button-orientation {
      margin-right: $space8;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}