.float-dialog {
  .image-miniature-dialog {
    display: inline-block;

    .loading-container {
      padding: 32px
    }

    img {
      display: inline-block;
      max-width: 100%;
      height: auto;
    }
  }
}