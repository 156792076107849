@use 'sass:math';
@import "Common";
@import "TinyMceEditorElementImage";

@mixin createElementInlineStyle(
  $url,
  $url2x,
  $color,
) {
  display: inline;
  background-color: rgba($color, 0.2);
  position: relative;

  &:after {
    @include balloon($color, #FFF);
    $size: 30px;
    width: $size;
    height: $size;
    content: ' ';
    $imageSet: url($url) 1x, url($url2x) 2x;
    background-image: url($url);
    background-image: image-set($imageSet);
    background-image: -webkit-image-set($imageSet);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 6px;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    margin-top: math.div($size, -2);
    left: 100%;
    margin-left: $space8;
  }

  &:hover,
  &:focus {
    &:after {
      z-index: 1;
      visibility: visible;
      opacity: 1;
    }
  }
}


@mixin editorElementMath() {
  $url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAA81BMVEVHcEzULl/VL1+/QED/AIDVLWDXLV/VLV/WLV7RLl3PMGDVLl/dM2bVLl/TLGTVLl/VK2DVL1/SLWLVLl/UMGDVMGDVLl/VLl/WLV/VL2DVLl/TMGDVLl/VLmDWL2DVLV/VLl7WLl7VL1/VLl/WMF/WLWDWLV/VLl/VL1/VLl/VLWDVLl/VLl/WLl/UK2LVLmDVLl/UL1/ULV/VLl/VLl/VLl/VLl/YMWLWL17bJG3VLmDVLl/VLl7VL17VK1XWLmHVMGDULl/fIGDWLmDWLl/VLV/TLF3TLGHbN1vVL17VLl/MM2bVLl//AADVLV7SLVrUL18RkhUGAAAAUXRSTlMAm5MEAlUzqUQLEN0P8hfnGJki5TUw7be/eO5A/kiI8VStpOFLSnx5K+JgdNRpLz1uiWuL5PPOGlEHkLF6PAYygFMIWNGvKR0O0M8FxgGSEYOXwJZCAAAAqklEQVQoz2NgoB1gtrI25oSyVeRlpaRhEpY2FrxGMI6cjCSSHj5TDphuCWVxFri4uZkhjKmgxKWoCuMI8JsIGmCznIeVlVUfVYhTXQ27Q7U12XF4gYlUCS0dbl2sEhocHHqoIkKeYj5CcJ4IUhiIcPE7wzj+wk4IGTcxbgEo056NjU0UJu7B4usSAGULMjIy+sEkHIW9eG1hHG8HRHgyuIvyIALG1Y7acQ8AO1ANzoKXN8cAAAAASUVORK5CYII=';
  $url2x: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAABmFBMVEVHcEzVLV/SLWDbJG3VLWDXLV/VL1/VL17WL2DUMGDSLVrYLl3jOVXXLV3PMGDXKF7/AADVLl/VLl/VK1XULV6/QEDVK2PRLl3fIGDMM2bWLl3VLl/VLl/VLV/TLGTTLF3VLl/VLl/VLl/VL17VLl/WLl7VL17VLl/VLl/RLl3VLl/VLl/VLl/WLl7XL17VLWDZL17VLl/WMF/YJ2L/AIDVLV/SLWLUL17TLF7ULV/VLV/VLl/TLGHULl/ULl/VK1zVLV/VLl/TMGDWLmDVMWHWLmDVL1/VLl7WLl/VLl/WLl/VLl/VLV/VL2DVLl/VLmDVL17VL17VMGDVLmDVLl/TL1/VLl/VL17ULmDVLV7VLV7VL1//VVXWLWDTLF/VLl7VLl7VLV/VL1/VLl/VLV/VLl3VLl/WLV7WLl7VLl/VLl7VL1/VLmDVLl/XLWDVLl/XLV7VLl/WLV/WL17VLl/MM2bVLl7ULl/VLWDUMF/VLl/VLl/VL1/VLmDVLl/VLl/WLWDVLV7VL1/VLl/VLl/VL1/bMWHVLl/p6sWaAAAAh3RSTlMAZigHVTOqZ4g1ESEJPxATAfX7BncEEgsIBSxO0p4XKf7v+NDPZFy8hhb87tZvJmAbeUsNAqMiQS5xW+M6m1MktN9AcCpYfv5eztHiqWLqSDwxMMC7RsQ2lUmSnwNKI/PAytvorjf0gmq2tZmQ/S3tOdO5V6wKiqeYO9fhVrDz3VBPk7LHrxWXR+M5AAABfElEQVRIx+1VxVIDQRTczbIENi4QiBCBEEiCu7u7u7u7u/ZvE5JcmJ1UZapyoSp9mENvd7335nXNclwSCYG6vVc5YiJIQZIEjsuSpGyZPtOAEAasBNuGtWwhF3aLzFDomkwbBCYIesaDnUMEWigdjYUOH9wkvwu/62gp1hzT6Jdxe8BBLH0+MERyx9uAe5mun/NgQU9w2nXs56FPTdMrjZhfJLjKVmzkOA2oogztAGZFUcz5w5by/DjHaXheKzN0IYK6eBfdmBpBeYIDVGRWMKgzisuAFAaD5ndIFoMqrYnNwHGKf2+oEJsBs6hju1aghOFawxATmh1VgS94f87r4zbwkbYLKMHq7qAZLm7vvhVBnMm/TAGdMaqYPnEjZ3V5q1lU+evLM54KGcZ+DBjx8EaQXmUU9TSL8AED8Uoboy8AGkjx6VXoeAeIOIjpUdSShs2Ty+svP+yUwhaB1o4jXDjXSlnpyrCW5nDavPlbtEWPoqeaLTW2muRvmxU/IWJXTCX2rKEAAAAASUVORK5CYII';
  $color: #D52E5F;
  $fontColor: #FFF;

  editor-element[type="math"] {
    @include createElementInlineStyle(
                    $url,
                    $url2x,
                    $color,
    );
  }

  editor-element[type="catalog"] {
    editor-element[type="math"] {
      &:after {
        margin-left: 4px;
        z-index: 10;
      }
    }
  }
}