@import "../../../../components/scss/Common";

  .simple-view-button {
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $colorTinyMceIconHover;
    }

    &[data-active="true"] {
      background-color: $colorTinyMceIconSelected;
    }
  }
