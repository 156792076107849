@import "../components/scss/Common";

.login-form {
    text-align: center;

    h2 {
      margin-top: $space64;
      margin-bottom: $space40;
    }

    form {
      text-align: center;
      margin-bottom: $space40;
      width: 70%;
      max-width: 100%;
    }
}