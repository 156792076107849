@import "Common";

@mixin createEditorElementListStyle() {
  display: flex;

  .symbol {
    width: 1.5em;
    text-align: center;
  }

  .value {
    min-width: 25px;
  }
}

@mixin editorElementList() {
  editor-element[type="list"] {
    @include createEditorElementListStyle();
  }
}