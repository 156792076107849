@import "../components/scss/Common";

.loading-indicator {
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;

  button.loading {
    border: none;
    margin-right: $space8;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
