@import "../../components/scss/Common";

.login-left-bar {
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;

  .logo-container {
    display: inline-block;
    background-color: $colorWhite;
    padding: 65px 52px 52px 52px;
    flex: none;

    img {
      max-width: 100%;
    }
  }

  .support-info {
    padding: 60px 0 120px 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;

    p {
      font-weight: lighter;
      margin: 0 0;
    }
  }

  .app-version {
    font-size: 9px;
    display: flex;
    align-items: flex-end
  }
}

