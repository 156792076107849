@import "./scss/Common";

.tag-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  font-size: 10px;
  background-color: $colorLightGray;
  padding: 0 6px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  height: 25px;
}

.remove-tag {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 5px;
  color: $colorDark;
  padding: 0;
}
