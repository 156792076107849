@use 'sass:math';
@import "Common";
@import 'GridCommon';

.gd-container {
  display: block;
  text-align: center;
}

.gd {
  display: inline-flex;
  align-items: stretch;
  text-align: left;
  width: $desktopWidth;
}

.gd-inner {
  display: inline-flex;
  align-items: stretch;
  vertical-align: top;
  flex: auto;

  > .gd-col {
    flex: auto;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
}

@for $i from 1 through $desktopCols {
    .gd-col-#{$i}--desktop {
      display: inline-flex;
      width: $colWidth * $i + ($i - 1) * $colSpacing;
      margin: 0 math.div($colSpacing, 2);

      > * {
        flex: auto;
      }
    }
}

@for $i from 1 through $desktopCols {
    .gd-skip-#{$i}--desktop {
      margin-left: $colWidth * $i + math.div($colSpacing, 2) * 2;
    }
}

// Improve input area inside a small column
.gd-col-1--desktop {
  input {
    padding-left: $space12;
    padding-right: $space12;
  }

  select {
    padding-left: $space12;
    padding-right: $space24;
    background-position: center right $space8;
  }
}

.gd-col--align-center {
  justify-content: center;
}

.gd-col--align-between {
  justify-content: space-between !important;
}

.gd-col--valign-middle {
  align-items: center;
}

.gd-col--valign-bottom {
  align-items: flex-end;
}

.gd--full-height,
.gd-col--full-height {
  height: 100%;
}