@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.dashboard {
  height: 100%;

  > .gd {
    height: 100%;
  }

  .dashboard-container {
    display: flex;
    flex-direction: column;

    > .controls {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space32;

      button {
        &:not(:first-of-type) {
          border-left: none;
        }
      }

      .admin-controls {
        text-align: right;
        flex: 1;

        a {
          cursor: pointer;
          padding: $space8 0;
          img {
            margin-left: $space16;
          }
        }
      }
    }

    .filter-controls {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px; // 42px in layout minus 20px of miniature margin to keep the right distance

      > div {
        display: inline-flex;

        a {
          display: inline-flex;
          font-size: 28px;
          font-weight: lighter;
          text-decoration: none;
          margin-right: $space140;
          align-items: center;
          cursor: pointer;

          &.disabled {
            opacity: 0.2;
            cursor: unset;
          }

          &:focus,
          &:hover, {
            text-decoration: none;
          }

          &.selected {
            font-weight: bold;
            text-decoration: underline;
          }

          img {
            margin-right: $space16;
          }
        }
      }

      > div {
        .search-field {
          width: 296px;
          margin-right: 16px;
        }
      }
    }

    .documents-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .scroll.list {
        overflow-x: auto;
      }

      .scroll {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        & .list {
          display: inline-grid;
          grid-template-columns: 33.33% 33.33% 33.33%;
          align-items: flex-start;
          justify-items: flex-start;
          width: 100%;

          > a {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            text-decoration: none;
            margin: 8px 0;
          }
        }

        & .grid {
          display: grid;
          grid-template-columns: 25% 25% 25% 25%;
          align-items: flex-end;
          justify-items: center;

          > a {
            display: flex;
            width: 100%;
            justify-content: center;
            text-decoration: none;
          }
        }
      }
    }
  }
}