@import "../components/scss/Common";
@import "../components/scss/GridCommon";

.dictionary-modal {
  .container {
    display: flex;
    flex-direction: column;
    height: 90%;
  }

  .table-form-container {
    flex: 1;
  }
}
